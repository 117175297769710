import * as React from 'react'
import { inject, observer } from 'mobx-react'

import RichText from 'src/components/Modules/RichText'

type Props = {
  pageContext: any
}

@observer
class Information extends React.Component<Props> {
  render() {
    const { pageContext } = this.props
    let { page } = pageContext
    console.log(pageContext)

    return (
      <div className="container">
        <h1>{page.title}</h1>
        <RichText content={page.review} />
      </div>
    )
  }
}

export default Information
